import React, { ErrorInfo } from 'react';
import { SignupErrorPage } from '../pages/SignupErrorPage';
import { SignupEventName, SignupLogger } from './SignupLogger';

export class SignupErrorBoundary extends React.Component<{ signupLogger: SignupLogger }> {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so next render shows fallback UI.
    return { error: error };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.props.signupLogger.logError({
      component: 'SignupErrorBoundary',
      event: SignupEventName.SIGNUP_ERROR_BOUNDARY_EXCEPTION,
      data: {
        error: error.toString(),
        componentStack: info.componentStack,
      },
    });
  }

  render() {
    if (this.state.error) {
      return <SignupErrorPage />;
    }
    return this.props.children;
  }
}
