import { Text } from '@snowflake/core-ui';
import { SignupApiErrorCode } from '@signup/shared';
import { default as React, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CardTemplate } from '../shared/CardTemplate';
import { checkmarkIcon, DotsLoadingIndicator, exclamationIcon } from '../shared/shared';
import { SignupParams } from '../../../utils/ParseQueryParams';
import { SurveyCard } from '../SurveyCard/SurveyCard';
import { ThankYouCardBody } from './ThankYouCardBody';

export interface ThankYouCardProps {
  signupParams: SignupParams;
  errorCode?: SignupApiErrorCode;
  accountCreationFinished: boolean;
  successTitle: string;
  successSubtitle: React.ReactChild;
}

export const ThankYouCard = (props: ThankYouCardProps) => {
  const { formatMessage } = useIntl();
  const [isSurveyResponseSubmitted, setIsSurveyResponseSubmitted] = useState(false);

  const handleSurveySubmission = () => {
    setIsSurveyResponseSubmitted(true);
  };

  const setErrorMessageText = (error: SignupApiErrorCode): React.ReactChild => {
    switch (error) {
      case SignupApiErrorCode.INVALID_SALES_ASSISTED_URL:
        return (
          <FormattedMessage id="This url has an error. Please check with your Snowflake representative to get a new url link." />
        );
      case SignupApiErrorCode.SUPPRESSED_COUNTRY:
        return (
          <Text>
            <FormattedMessage id="We are unable to provision a Snowflake account in your country due to certain restrictions." />
            <br />
            <br />
            <FormattedMessage id="Please do not contact Snowflake Technical Support, as they will not be able to assist you." />
          </Text>
        );
      case SignupApiErrorCode.SUPPRESSED_EMAIL:
        return (
          <Text>
            <FormattedMessage id="We are unable to verify the email address submitted." />
            <br />
            <br />
            <FormattedMessage id="Please do not contact Snowflake Technical Support, as they will not be able to assist you." />
          </Text>
        );
      case SignupApiErrorCode.SUPPRESSED_GENERIC:
        return (
          <Text>
            <FormattedMessage id="We are unable to provision a Snowflake account due to certain restrictions." />
            <br />
            <br />
            <FormattedMessage id="Please do not contact Snowflake Technical Support, as they will not be able to assist you." />
          </Text>
        );
      case SignupApiErrorCode.GENERIC_ERROR:
        return (
          <FormattedMessage id="Looks like we are having an issue provisioning your account. Please try again later." />
        );
      default:
        return (
          <FormattedMessage id="Looks like we are having an issue provisioning your account. Please try again later." />
        );
    }
  };

  let thankYouTitle = formatMessage({ id: 'Account Setup in Progress' });
  let thankYouSubtitle: React.ReactChild = (
    <FormattedMessage id="Your account setup may take a few minutes. While you're waiting, learn more about Snowflake." />
  );
  let icon = <DotsLoadingIndicator />;
  if (!!props.errorCode) {
    thankYouTitle = formatMessage({ id: 'Whoops!' });
    thankYouSubtitle = setErrorMessageText(props.errorCode);
    icon = exclamationIcon;
  } else if (props.accountCreationFinished) {
    if (props.signupParams.eng) {
      thankYouTitle = formatMessage({ id: "We're working on it" });
      thankYouSubtitle = (
        <FormattedMessage id="Your account is being setup. A Snowflake representative will send your activation link shortly. In the meantime, please use these resources to get started." />
      );
      icon = checkmarkIcon;
    } else {
      thankYouTitle = props.successTitle.toUpperCase();
      thankYouSubtitle = props.successSubtitle;
      icon = checkmarkIcon;
    }
  }

  //Don't show survey for sales assisted or listing signups
  const isSignupTypeValidForSurvey = () => {
    if (props.signupParams.eng || props.signupParams.listing) {
      return false;
    }
    return true;
  };

  if (isSignupTypeValidForSurvey() && !isSurveyResponseSubmitted) {
    return <SurveyCard markAsSubmitted={handleSurveySubmission} />;
  }

  const renderThankYouCardWithSurvey = () => {
    return (
      <>
        <FormattedMessage id="Thank you for your response!" />
        <ThankYouCardBody />
      </>
    );
  };
  return (
    <CardTemplate title={thankYouTitle} subtitle={thankYouSubtitle} aux={icon}>
      {isSignupTypeValidForSurvey() ? renderThankYouCardWithSurvey() : <ThankYouCardBody />}
    </CardTemplate>
  );
};
