import React from 'react';
import { TrialType } from '@signup/shared';
import { EmailOptInAgreement } from './EmailOptInAgreement';
import {
  isCountryISOCodeInAPJ,
  isCountryISOCodeInGDPR,
  isCountryISOCodeInUS,
} from '../utils/SignupCountryUtils';
import { EmailOptOutAgreement } from './EmailOptOutAgreement';

interface EmailAgreementProps {
  trialType: TrialType | undefined;
  optInEmailAgreement: boolean;
  optOutEmailAgreement: boolean | undefined;
  setOptInEmailAgreement: (optIn: boolean) => void;
  setOptOutEmailAgreement: (optOut: boolean) => void;
  countryIsoCode: string;
}

export const EmailAgreement = ({
  trialType,
  optInEmailAgreement,
  setOptInEmailAgreement,
  optOutEmailAgreement,
  setOptOutEmailAgreement,
  countryIsoCode,
}: EmailAgreementProps) => {
  if (trialType === TrialType.TRIAL_LEARN) {
    return (
      <EmailOptInAgreement
        optInEmailAgreement={optInEmailAgreement}
        optInAgreementOnChangeHandler={(optIn: boolean) => setOptInEmailAgreement(!optIn)}
      />
    );
  }
  if (countryIsoCode) {
    if (isCountryISOCodeInAPJ(countryIsoCode) || isCountryISOCodeInUS(countryIsoCode)) {
      return null;
    } else if (isCountryISOCodeInGDPR(countryIsoCode)) {
      return (
        <EmailOptInAgreement
          optInEmailAgreement={optInEmailAgreement}
          optInAgreementOnChangeHandler={(optIn: boolean) => setOptInEmailAgreement(!optIn)}
        />
      );
    } else {
      return (
        <EmailOptOutAgreement
          optOutEmailAgreement={optOutEmailAgreement}
          optOutAgreementOnChangeHandler={(optOut?: boolean) => setOptOutEmailAgreement(!optOut)}
        />
      );
    }
  }
  return null;
};
