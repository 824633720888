import { default as React, useCallback } from 'react';
import {
  Block,
  Color,
  Column,
  Input,
  RadioGroup,
  Row,
  Selector,
  SelectorOptionType,
  Text,
} from '@snowflake/core-ui';
import InputTitle from './InputTitle';
import { ensureUnreachable } from '@signup/shared/src/utils/ensure';
import { Cloud } from '@signup/shared';
import { CloudLogoContainer } from '../SignupCard/CloudLogoContainer';
import { SignupFormFields, useSignupFormData } from './SignupFormDataContext';
import { useSignupFormController } from './SignupFormControllerContext';
import { FormattedMessage } from 'react-intl';

interface BaseInputType {
  title: string;
  subtitle?: string;
  fieldName: SignupFormFields;
  placeholder?: string;
  rightHandSideFloater?: { text: string; link?: string };
}

export interface TextInput extends BaseInputType {
  type: 'text';
}

export interface SelectorInput<T extends string> extends BaseInputType {
  type: 'selector';
  options: SelectorOptionType<T>[];
}

export interface CloudSelectorInput extends BaseInputType {
  type: 'cloud';
  options: Array<{
    src: string;
    title: string;
    value: Cloud;
  }>;
}

export type FormInputConfig = TextInput | SelectorInput<string> | CloudSelectorInput;

export type FormInputsConfig = Array<Array<FormInputConfig>>;

const SignupFormInput = ({
  forceRequired,
  ...props
}: React.ComponentProps<typeof Input> & { forceRequired: boolean }) => {
  const [blurred, setBlurred] = React.useState(false);
  return <Input required={blurred || forceRequired} onBlur={() => setBlurred(true)} {...props} />;
};

const SignupFormSelector = ({
  forceRequired,
  ...props
}: React.ComponentProps<typeof Selector> & { forceRequired: boolean }) => {
  const [blurred, setBlurred] = React.useState(false);
  return (
    <Selector required={blurred || forceRequired} onBlur={() => setBlurred(true)} {...props} />
  );
};

type SignupFormInputsProps = {
  configs: FormInputsConfig;
  forceRequired: boolean;
};

function useRenderInput() {
  const formData = useSignupFormData();
  const { setField } = formData;
  const { validateField } = useSignupFormController();

  return useCallback(
    (input: FormInputConfig, forceRequired: boolean) => {
      const { fieldName, placeholder } = input;
      switch (input.type) {
        case 'text':
          return (
            <SignupFormInput
              value={formData[fieldName] as string}
              onChange={e => setField(fieldName, e.currentTarget.value)}
              validation={val => validateField(fieldName, val)}
              width="100%"
              forceRequired={forceRequired}
              data-testid={`${fieldName}-input`}
            />
          );
        case 'selector':
          return (
            <SignupFormSelector
              options={input.options}
              value={formData[fieldName] as string}
              placeholder={placeholder}
              onChange={e => setField(fieldName, e.currentTarget.value)}
              validation={val => validateField(fieldName, val as string)}
              width="100%"
              rootProps={{ width: '100%' }}
              forceRequired={forceRequired}
              dropdownMaxWidth="338px"
              data-testid={`${fieldName}-input`}
            />
          );
        case 'cloud':
          return (
            <Column width="100%">
              {forceRequired && !formData[fieldName] && (
                <Text color={Color.Red50} size="small" marginBottom="8px">
                  <FormattedMessage id="Choosing a cloud provider is required" />
                </Text>
              )}
              <RadioGroup name="cloud" aria-label="Cloud provider">
                <Block display="flex" justifyContent="space-between">
                  {input.options.map((option, idx) => (
                    <CloudLogoContainer
                      key={idx}
                      src={option.src}
                      title={option.title}
                      value={option.value}
                      selected={formData[fieldName] === option.value}
                      handleSelect={selectedCloud => setField(fieldName, selectedCloud)}
                    />
                  ))}
                </Block>
              </RadioGroup>
            </Column>
          );
        default:
          ensureUnreachable(input);
      }
    },
    [setField, formData, validateField],
  );
}

export const SignupFormInputs = ({ configs, forceRequired }: SignupFormInputsProps) => {
  const renderInput = useRenderInput();

  return (
    <Column width="100%" rowGap="16px">
      {configs.map((row, idx) => (
        <Row key={idx} columnGap="12px">
          {row.map((input, idx) => (
            <Column key={idx} width="100%">
              <InputTitle
                title={input.title}
                subtitle={input.subtitle}
                rightHandSideFloater={input.rightHandSideFloater}
              >
                {renderInput(input, forceRequired)}
              </InputTitle>
            </Column>
          ))}
        </Row>
      ))}
    </Column>
  );
};
