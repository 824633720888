import { useTelemetry } from '@snowflake/core-ui';
import React, { useCallback } from 'react';

export function useLogOnClose(
  logInfo: { message: string; startTime: number },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalData: { [key: string]: any } = {},
) {
  //Set up Telemetry
  const { logNavigation } = useTelemetry();

  const logPageExit = useCallback(() => {
    const timeSpentOnStep = Date.now() / 1000 - logInfo.startTime;
    logNavigation(logInfo.message, {
      timeSpentOnStep,
      ...additionalData,
    });
  }, [logInfo, additionalData, logNavigation]);

  React.useEffect(() => {
    window.addEventListener('beforeunload', logPageExit);

    return () => {
      window.removeEventListener('beforeunload', logPageExit);
    };
  }, [logInfo, logPageExit]);
}
