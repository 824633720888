export function ensureUnreachable(x: never): never {
  throw new Error(`Unexpected value: ${x}`);
}

export function ensureExists<T>(value: T, message?: string): NonNullable<T> {
  if (value === null || value === undefined) {
    throw new Error(message || 'Expected value to exist');
  }
  return value as NonNullable<T>;
}
