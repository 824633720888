import { Block, Button, CheckList, Column, Input, LinkButton, Row, Text } from '@snowflake/core-ui';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { isOtherOption, LabeledValue, OptionLabeledValue, Question, QuestionResult } from './model';
import { ProgressBar } from './ProgressBar';
import { OrDivider } from '../shared/OrDivider';
import { getExperimentGroup } from '../../../utils/configurations';
import { ABExperimentName } from '@signup/shared';

export type SurveyQuestionProps = {
  readonly question: Question;
  readonly currentStep: number;
  readonly totalSteps: number;
  readonly onSubmit: (selections: QuestionResult) => void;
  readonly onSkip: () => void;

  // In the original signup survey, the progress bar is at the top. The redesign moves it to the bottom.
  readonly progressBarAtBottom?: boolean;
};

const initialState: { [key: string]: boolean } = {};

export function SurveyQuestion(props: SurveyQuestionProps) {
  const { formatMessage } = useIntl();
  const isV2 = getExperimentGroup(ABExperimentName.SIGNUP_FORM_REWORK) === 'ENABLED';

  const { options: surveyOptions, questionText } = props.question;
  const { currentStep, totalSteps, progressBarAtBottom } = props;
  const isLastQuestion = currentStep === totalSteps;

  const [selectedOptions, setSelectedOptions] = useState(initialState);
  const [otherText, setOtherText] = useState<string>('');

  const isOtherTextEnabled = (option: OptionLabeledValue): boolean =>
    option.allowUserInput === true && isOtherOption(option);

  const onSubmit = useCallback(() => {
    const { questionText: question, version } = props.question;

    const answers = surveyOptions.flat().reduce((acc, curr) => {
      return acc[curr.value] !== undefined ? acc : { ...acc, [curr.value]: false };
    }, selectedOptions);

    const currentState: QuestionResult = {
      version: version,
      questionValue: question.value,
      options: answers,
      otherOptionInput: otherText,
    };
    setSelectedOptions(initialState);
    setOtherText('');
    props.onSubmit(currentState);
  }, [props, selectedOptions, otherText, surveyOptions]);

  const onSkip = useCallback(() => {
    setSelectedOptions(initialState);
    setOtherText('');
    props.onSkip();
  }, [props, setSelectedOptions, setOtherText]);

  const onToggleSelection = useCallback(
    (option: LabeledValue) => {
      const currentState = selectedOptions[option.value] || false;
      const updatedOptions = {
        ...selectedOptions,
        [option.value]: !currentState,
      };
      setSelectedOptions(updatedOptions);

      // Let's clear text in other box if unchecked
      if (isOtherTextEnabled(option) && currentState === true) {
        setOtherText('');
      }
    },
    [selectedOptions],
  );

  //submit button is disabled if user has not selected any options in the survey
  const isSubmitButtonDisabled = Object.values(selectedOptions).every(val => val === false);

  const createLabelFor = useCallback(
    (option: OptionLabeledValue): any => {
      if (isOtherTextEnabled(option)) {
        return (
          <Row verticalAlign="top">
            <Text>{option.label}</Text>
            <Input
              value={otherText}
              onChange={event => setOtherText(event.target.value)}
              placeholder={formatMessage({ id: 'Tell us more' })}
              size="small"
              marginLeft={8}
              marginTop="-3px"
              disabled={selectedOptions[option.value] !== true}
              maxLength={50}
            />
          </Row>
        );
      }
      return option.label;
    },
    [otherText, formatMessage, selectedOptions],
  );

  const renderTopProgressBar = useCallback(() => {
    if (totalSteps === 1) {
      return null;
    }
    return (
      <Block paddingBottom={12} role="progressbar" data-testid="top-progressbar">
        <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
      </Block>
    );
  }, [totalSteps, currentStep]);

  const renderBottomProgressBar = useCallback(() => {
    if (totalSteps === 1) {
      return null;
    }
    return (
      <Block
        alignContent="center"
        role="progressbar"
        data-testid="bottom-progressbar"
        marginRight="auto"
      >
        <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
      </Block>
    );
  }, [totalSteps, currentStep]);

  const checkListOptions = useMemo(() => {
    const elements: JSX.Element[] = [];
    for (let idx = 0; idx < surveyOptions.length; ++idx) {
      const section = surveyOptions[idx];
      const checkListOptions = section.map((option, index) => {
        return {
          id: `option-${idx}-${index}`,
          checked: selectedOptions[option.value] || false,
          value: option.value,
          label: createLabelFor(option),
          disabled: isV2 && option.shouldDisable?.(selectedOptions),
          onChange: () => onToggleSelection(option),
        };
      });
      elements.push(<CheckList key={idx} options={checkListOptions} />);

      if (idx < surveyOptions.length - 1) {
        elements.push(<OrDivider key={`divider-${idx}`} />);
      }
    }
    return elements;
  }, [surveyOptions, selectedOptions, createLabelFor, onToggleSelection, isV2]);

  const questionSection = (
    <>
      {!progressBarAtBottom && renderTopProgressBar()}
      <Text weight="semi-bold" paddingBottom={16}>
        {questionText.label}
      </Text>
      {checkListOptions}
    </>
  );

  const actionSection = (
    <Row align="right" paddingTop={16}>
      {progressBarAtBottom && renderBottomProgressBar()}
      <LinkButton paddingRight={4} use="text" href="#" onClick={onSkip}>
        <FormattedMessage id="Skip" />
      </LinkButton>
      <Button use="primary" onClick={onSubmit} disabled={isSubmitButtonDisabled}>
        {isLastQuestion ? <FormattedMessage id="Submit" /> : <FormattedMessage id="Continue" />}
      </Button>
    </Row>
  );

  if (isV2) {
    return (
      <Column minHeight="400px" verticalAlign="stretch">
        <Column>{questionSection}</Column>
        {actionSection}
      </Column>
    );
  }

  return (
    <>
      {questionSection}
      {actionSection}
    </>
  );
}
