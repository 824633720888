import { Block } from '@snowflake/core-ui';
import * as React from 'react';

export const Header = () => {
  // trial-header-right is used to make the logo align with the marketing text at the bottom
  return (
    <Block
      className="trial-page-header"
      paddingTop="24px"
      paddingBottom="24px"
      paddingLeft="48px"
      paddingRight="48px"
      display="flex"
      alignItems="center"
    >
      <Block className="trial-header-left">
        <a href="https://www.snowflake.com">
          <img src="/static/images/logo-sno-blue.svg" />
        </a>
      </Block>
      <Block className="trial-header-right"></Block>
    </Block>
  );
};
