import { Color, Column, Link, Row, Text } from '@snowflake/core-ui';
import React from 'react';

interface InputTitleProps {
  title: string;
  subtitle?: string;
  rightHandSideFloater?: { text: string; link?: string };
  children: React.ReactNode;
}

/**
 * A quick wrapper of input items to render a title on the top of the input.
 */
const InputTitle: React.FC<InputTitleProps> = ({
  title,
  subtitle,
  children,
  rightHandSideFloater,
}) => {
  return (
    <Column width="100%" rowGap="8px">
      <Column rowGap="4px">
        <Row align="justify">
          <Column>
            <Text fontWeight="500">{title}</Text>
          </Column>
          {rightHandSideFloater && (
            <Column>
              {rightHandSideFloater.link ? (
                <Link external={true} href={rightHandSideFloater.link}>
                  {rightHandSideFloater.text}
                </Link>
              ) : (
                <Text>{rightHandSideFloater.text}</Text>
              )}
            </Column>
          )}
        </Row>
        {subtitle && (
          <Row>
            <Text size="small" color={Color.Gray70}>
              {subtitle}
            </Text>
          </Row>
        )}
      </Column>
      <Row>{children}</Row>
    </Column>
  );
};

export default InputTitle;
