import { default as React } from 'react';
import { Block, Color, Text } from '@snowflake/core-ui';
import { Cloud } from '@signup/shared';

interface CloudLogoContainerProps {
  src: string;
  title: string;
  value: Cloud | '';
  selected: boolean;
  handleSelect: (cloud: Cloud) => void;
}

export const CloudLogoContainer = (props: CloudLogoContainerProps) => {
  const { src, title, value, selected, handleSelect } = props;
  return (
    <Block width="30%">
      <Block
        className="trial-logo-box"
        borderColor={selected ? Color.Blue50 : Color.Gray40}
        borderRadius={4}
        boxSizing="border-box"
        boxShadow={selected ? `inset 0 0 0 1px ${Color.Blue50}` : ''}
        borderWidth={1}
        marginBottom={4}
        borderStyle="solid"
        padding="12%"
        cursor="pointer"
        {...{ ':hover': { borderColor: selected ? Color.Blue60 : Color.Gray60 } }}
        onClick={() => handleSelect(value as Cloud)}
        width="100%"
        display="flex"
        alignItems="center"
      >
        <Block opacity={selected ? 1 : 0.6} width="100%">
          <img src={src} alt={title} width="100%" />
        </Block>
      </Block>
      <Text size="small" textAlign="center" color={Color.Gray70}>
        {title}
      </Text>
    </Block>
  );
};
