import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { getLocale } from '../utils/AsyncIntlProvider';
import { useRenderingOptions } from '../utils/useRenderingOptions';
import { RenderingOptions } from '../types';
import { useTelemetry } from '@snowflake/core-ui';
import { SignupEventName } from '../utils/SignupLogger';
import { SignupEventType } from '@signup/shared';
import {
  getClientVersion,
  getCoordinates,
  getExperimentGroups,
  getFeatureFlags,
  getIsoCode,
  getReferrerUrl,
  getSignupFlowType,
} from '../utils/configurations';

interface StateVariable<T> {
  value: T;
  setValue: (value: T) => void;
}

interface SignupPageState {
  videoShowing: StateVariable<boolean>;
  formSubmitted: StateVariable<boolean>;
  locale: StateVariable<string>;
  docsLocale: StateVariable<string>;
  renderingOptions: RenderingOptions;
}

interface props {
  children: ReactNode;
}

//locales used in the product are different from those in the docs
//the mapping is used to localize the docs links
const getDocsLocale = (locale: string) => {
  switch (locale) {
    case 'en-US':
      return 'en';
    case 'fr-FR':
      return 'fr';
    case 'ja':
      return 'ja';
    case 'ko':
      return 'ko';
    default:
      return 'en';
  }
};

const SignupPageContext = createContext<SignupPageState>({} as SignupPageState);

export const SignupPageProvider = ({ children }: props) => {
  //initialize the state
  const [videoShowing, setVideoShowing] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [locale, setLocale] = useState(getLocale());
  const [docsLocale, setDocsLocale] = useState(getDocsLocale(getLocale()));
  const renderingOptions = useRenderingOptions();
  const { logEvent } = useTelemetry();

  // only calls once.
  React.useEffect(() => {
    logEvent({
      event: SignupEventName.SIGNUP_FORM_RENDERING,
      type: SignupEventType.UI_RESPONSE_SUCCESS,
      data: {
        signupFlowType: getSignupFlowType(),
        clientVersion: getClientVersion(),
        isoCode: getIsoCode(),
        coordiates: getCoordinates(),
        locale,
        docsLocale,
        experimentGroups: getExperimentGroups(),
        featureFlags: getFeatureFlags(),
        referralUrl: getReferrerUrl(),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialState: SignupPageState = useMemo(
    () => ({
      videoShowing: {
        value: videoShowing,
        setValue: setVideoShowing,
      },
      formSubmitted: {
        value: formSubmitted,
        setValue: setFormSubmitted,
      },
      locale: {
        value: locale,
        setValue: setLocale,
      },
      docsLocale: {
        value: docsLocale,
        setValue: setDocsLocale,
      },
      renderingOptions,
    }),
    [renderingOptions, videoShowing, formSubmitted, locale, docsLocale],
  );

  return <SignupPageContext.Provider value={initialState}>{children}</SignupPageContext.Provider>;
};

export const useSignupPageContext = () => {
  return useContext<SignupPageState>(SignupPageContext);
};
