import { Color, Text, TextLink } from '@snowflake/core-ui';
import { FormattedMessage } from 'react-intl';
import { HTMLAttributeAnchorTarget, default as React } from 'react';
import { isCountryISOCodeInAPJ } from '../../utils/SignupCountryUtils';

export interface AgreementTextProps {
  countryIsoCode: string;
  linkTarget?: HTMLAttributeAnchorTarget;
}

export const SignupAgreement = ({ linkTarget, countryIsoCode }: AgreementTextProps) => {
  if (isCountryISOCodeInAPJ(countryIsoCode)) {
    return (
      <Text size="small" color={Color.Gray70}>
        <FormattedMessage
          id="By clicking the button below you understand that Snowflake will process your personal information in accordance with its <privacynotice>Privacy Notice</privacynotice>. I may withdraw my consent through <unsubscribelink>unsubscribe links</unsubscribelink> at any time."
          values={{
            privacynotice: function PrivacyNotice(...chunks: string[]) {
              return (
                <TextLink target={linkTarget} href="https://www.snowflake.com/privacy-policy/">
                  {chunks}
                </TextLink>
              );
            },
            unsubscribelink: function UnsubscribeLink(...chunks: string[]) {
              return (
                <TextLink
                  target={linkTarget}
                  href="https://info.snowflake.com/2020-Snowflake-Preference-Center.html"
                >
                  {chunks}
                </TextLink>
              );
            },
          }}
        />
      </Text>
    );
  } else {
    return (
      <Text size="small" color={Color.Gray70}>
        <FormattedMessage
          id="By clicking the button below you understand that Snowflake will process your personal information in accordance with its <privacynotice>Privacy Notice</privacynotice>"
          values={{
            privacynotice: function PrivacyNotice(...chunks: string[]) {
              return (
                <TextLink target={linkTarget} href="https://www.snowflake.com/privacy-policy/">
                  {chunks}
                </TextLink>
              );
            },
          }}
        />
      </Text>
    );
  }
};
SignupAgreement.displayName = 'SignupAgreement';
