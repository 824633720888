import { Block, Color, Text } from '@snowflake/core-ui';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export const CertificationsFooter = () => {
  return (
    <Block
      backgroundColor={Color.White}
      paddingTop={24}
      paddingBottom={24}
      paddingRight="20px"
      paddingLeft="20px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      lineHeight="26px"
      color={Color.Gray70}
      fontSize="small"
      textAlign="center"
    >
      <Text marginBottom={8}>
        <FormattedMessage id="Snowflake is HIPAA, PCI DSS, SOC 1 and SOC 2 Type 2 compliant, and FedRAMP Authorized" />
      </Text>
      <Block display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
        <Block marginRight={8} marginLeft={8}>
          <img src="/static/images/compliance/blue/pci.png" height="40px" alt="PCI" />
        </Block>
        <Block marginRight={8} marginLeft={8}>
          <img src="/static/images/compliance/blue/hipaa.png" height="40px" alt="HIPAA" />
        </Block>
        <Block marginRight={8} marginLeft={8}>
          <img src="/static/images/compliance/blue/soc2.png" height="40px" alt="SOC 2" />
        </Block>
        <Block marginRight={8} marginLeft={8}>
          <img src="/static/images/compliance/blue/fedramp.png" height="40px" alt="FedRAMP" />
        </Block>
      </Block>
    </Block>
  );
};
