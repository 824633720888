import { Text, TextLink } from '@snowflake/core-ui';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export const FooterCommPreference = () => {
  return (
    <Text>
      <FormattedMessage
        id="If you'd rather not receive future emails from Snowflake, <communicationlink>unsubscribe here or customize your communication preferences</communicationlink>"
        values={{
          communicationlink: function CommunicationLink(...chunks: string[]) {
            return (
              <TextLink href="https://info.snowflake.com/2020-Snowflake-Preference-Center.html">
                {chunks}
              </TextLink>
            );
          },
        }}
      />
    </Text>
  );
};
FooterCommPreference.displayName = 'FooterCommPreference';
