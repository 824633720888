import React, { useCallback, useMemo, useState } from 'react';
import { CardTemplate } from '../../shared/CardTemplate';
import { SurveyQuestion } from '../../SurveyCard/SurveyQuestion';
import { FormattedMessage } from 'react-intl';
import {
  AccessibleSection,
  Text,
  Block,
  AccessibleHeader,
  useUniqueID,
  Color,
  useTelemetry,
} from '@snowflake/core-ui';
import {
  usePreferredLanguageQuestion,
  useSnowflakeUsageQuestion,
} from '../../SurveyCard/questions';
import { QuestionResult } from '../../SurveyCard/model';
import { shuffle } from 'lodash';
import { SignupEventName } from '../../../../utils/SignupLogger';

interface Props {
  continueNext: () => void;
}

export const SignupSurveyCard = (props: Props) => {
  const { continueNext } = props;
  const { logAction } = useTelemetry();

  // shuffle question order to avoid bias.
  const usageQuestion = useSnowflakeUsageQuestion();
  const languageQuestion = usePreferredLanguageQuestion();
  const surveyQuestions = useMemo(() => {
    return shuffle([usageQuestion, languageQuestion]);
  }, [usageQuestion, languageQuestion]);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const currentQuestion = surveyQuestions[currentQuestionIndex];
  const currentStep = currentQuestionIndex + 1;
  const totalSteps = surveyQuestions.length;
  const isLastQuestion = currentStep === totalSteps;
  const id = useUniqueID();

  const nextQuestionOrMarkSubmitted = useCallback(() => {
    if (isLastQuestion) {
      continueNext();
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  }, [currentQuestionIndex, continueNext, isLastQuestion]);

  const onQuestionSubmit = useCallback(
    (result: QuestionResult) => {
      const telemetryEntry = {
        question: result.questionValue,
        version: result.version,
        selection: {
          ...result.options,
          otherUserInput: result.otherOptionInput,
        },
        questionOrder: surveyQuestions.map(q => q.questionText.value),
        optionOrder: currentQuestion.options.flat().map(o => o.value),
      };
      logAction(
        SignupEventName.POST_SIGNUP_ACQUISITION_SOURCE_SURVEY,
        'ui_click',
        'SurveyCard',
        telemetryEntry,
      );

      nextQuestionOrMarkSubmitted();
    },
    [nextQuestionOrMarkSubmitted, currentQuestion, surveyQuestions, logAction],
  );

  // Render header directly instead of passing it into CardTemplate because it's using InfoCard that
  // doesn't allow customization of the title paddings.
  const renderHeader = () => {
    return (
      <Block marginLeft="16px">
        <Block marginBottom={12}>
          <Text size="large" weight="bold">
            <AccessibleHeader id={id}>
              <FormattedMessage id="Almost there..." />
            </AccessibleHeader>
          </Text>
        </Block>
        <AccessibleSection>
          <Block marginBottom={24}>
            <FormattedMessage id="We’re working on setting up your account. Help us better serve you by answering these questions." />
          </Block>
        </AccessibleSection>
      </Block>
    );
  };

  return (
    <CardTemplate
      cardProps={{
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingBottom: '24px',
        fullbleed: true,
      }}
    >
      {renderHeader()}
      <Block
        boxSizing="border-box"
        borderWidth={1}
        borderStyle="solid"
        borderRadius="16px"
        borderColor={Color.Gray30}
        backgroundColor={Color.Gray10}
      >
        <Block margin={['20px', '24px']}>
          <SurveyQuestion
            currentStep={currentStep}
            totalSteps={totalSteps}
            onSubmit={onQuestionSubmit}
            onSkip={nextQuestionOrMarkSubmitted}
            question={currentQuestion}
            progressBarAtBottom
          />
        </Block>
      </Block>
    </CardTemplate>
  );
};
