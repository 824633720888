import React, { useMemo } from 'react';
import { Block, Color, Column, Icon, LoadingIndicator, Text } from '@snowflake/core-ui';
import { FormattedMessage } from 'react-intl';
import { useSignupFormData } from './SignupFormDataContext';
import { useSignupFormController } from './SignupFormControllerContext';
import { SignupApiErrorCode } from '@signup/shared';

export const SignupCompleteHeader = () => {
  const { email } = useSignupFormData();
  const { accountCreationFinished, creationErrorCode } = useSignupFormController();

  const icon = useMemo(() => {
    if (!accountCreationFinished) {
      return <LoadingIndicator />;
    }

    if (creationErrorCode) {
      return (
        <Block
          height="40px"
          width="40px"
          borderRadius="50%"
          overflow="hidden"
          backgroundColor={Color.Red50}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Icon icon="x" size={32} color={Color.White} backgroundColor="transparent" />
        </Block>
      );
    }

    return (
      <Block borderRadius="50%" overflow="hidden">
        <Icon
          icon="checkmark"
          size={40 as any /* 40 is from design but is not a standard size */}
          color={Color.White}
          backgroundColor={Color.Green70}
        />
      </Block>
    );
  }, [accountCreationFinished, creationErrorCode]);

  const title = useMemo(() => {
    if (!accountCreationFinished) {
      return <FormattedMessage id="Setting up your account..." />;
    }

    if (!creationErrorCode) {
      return <FormattedMessage id="Check your inbox!" />;
    }

    return <FormattedMessage id="Something went wrong" />;
  }, [accountCreationFinished, creationErrorCode]);

  const subtitle = useMemo(() => {
    if (!accountCreationFinished) {
      return null;
    }

    if (creationErrorCode) {
      switch (creationErrorCode) {
        case SignupApiErrorCode.SUPPRESSED_EMAIL:
          return (
            <Column rowGap="16px">
              <Text>
                <FormattedMessage
                  id="We weren’t able to verify the email address you entered: {email}"
                  values={{
                    email: <Text weight="semi-bold">{email}</Text>,
                  }}
                />
              </Text>
              <FormattedMessage id="Double check your email address and try again." />
            </Column>
          );
        case SignupApiErrorCode.INVALID_SALES_ASSISTED_URL:
          return (
            <FormattedMessage id="This url has an error. Please check with your Snowflake representative to get a new url link." />
          );
        default:
          return (
            <FormattedMessage id="Something went wrong: Your account hasn't been created yet." />
          );
      }
    }

    return (
      <FormattedMessage
        id="An email to activate your account has been sent to <emailformat>{emailaddress}</emailformat>. It may take a few minutes to arrive."
        values={{
          emailaddress: email,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          emailformat: function EmailFormat(...chunks: any[]) {
            return (
              <Text weight="semi-bold" display="inline">
                {chunks}
              </Text>
            );
          },
        }}
      />
    );
  }, [accountCreationFinished, email, creationErrorCode]);

  return (
    <>
      {/* paddingtop of CardTemplate can't be configured. so we offset it with -8px below to match the design */}
      <Block textAlign="center" marginTop="-8px">
        <Block width="fit-content" margin="auto">
          {icon}
        </Block>
        <Block marginTop="16px" fontSize="20px" fontWeight={600}>
          {title}
        </Block>
        <Block fontSize="14px" marginTop="12px" lineHeight="150%">
          {subtitle}
        </Block>
      </Block>
    </>
  );
};
