import { useMemo } from 'react';
import { useIntl } from 'react-intl';

// A value that has a localized label to be used in the UI.
export type LabeledValue = {
  readonly value: string;
  readonly label: string;
  readonly shouldDisable?: (selectedOptions: { [key: string]: boolean }) => boolean;
};

export type OptionLabeledValue = LabeledValue & {
  readonly allowUserInput?: boolean;
};

export type Question = {
  readonly version: string;
  readonly questionText: LabeledValue;
  readonly options: readonly OptionLabeledValue[][];
};

export type OptionResults = {
  readonly [key: string]: boolean;
};

// option keys & question value always refers to a value field from the supplied LabeledValue
// For backward compatibility, options will include Other as an option
// and otherOptionInput will include the text from the other option.
export type QuestionResult = {
  readonly questionValue: string;
  readonly version: string;
  readonly options: OptionResults;
  readonly otherOptionInput: string;
};

export const otherOptionValue = 'Other';

export const isOtherOption = (option: LabeledValue): boolean => option.value === otherOptionValue;

export const useOtherOption = (allowUserInput: boolean): OptionLabeledValue => {
  const { formatMessage } = useIntl();
  return useMemo(
    () => ({
      value: otherOptionValue,
      label: formatMessage({ id: 'Other' }),
      allowUserInput,
    }),
    [formatMessage, allowUserInput],
  );
};
