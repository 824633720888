import { FullEvent } from '@snowflake/core-ui';
import { SignupEventType } from '@signup/shared';
import { parseQueryParams } from './ParseQueryParams';
import { SignupApi } from '../api/signupApi';

export enum SignupSteps {
  INITIAL_LOAD = 'initial_load',
  FIRST_FORM = 'first_form',
  SECOND_FORM = 'second_form',
  THANK_YOU_PAGE = 'thank_you_page',
}

export enum AzureSteps {
  AZURE_INITIAL_LOAD = 'azure_initial_load',
  AZURE_FORM = 'azure_form',
  AZURE_THANK_YOU_PAGE = 'azure_thank_you_page',
}

export enum AwsSteps {
  AWS_INITIAL_LOAD = 'aws_initial_load',
  AWS_FORM = 'aws_form',
  AWS_THANK_YOU_PAGE = 'aws_thank_you_page',
}

export enum SignupEventName {
  SIGNUP_FORM_RENDERING = 'signup_form_rendering',
  SIGNUP_FORM_TWO_VALIDATION_ERROR_ON_SUBMIT = 'signup_form_two_validation_error_on_submit',
  SIGNUP_FORM_TWO_COMPLETE = 'signup_form_two_complete',
  SIGNUP_FORM_ONE_VALIDATION_ERROR_ON_SUBMIT = 'signup_form_one_validation_error_on_submit',
  SIGNUP_FORM_ONE_COMPLETE = 'signup_form_one_complete',
  SIGNUP_VALIDATE_EMAIL = 'signup_validate_email',
  SIGNUP_SELECT_EDITION = 'signup_select_edition',
  SIGNUP_SELECT_CLOUD = 'signup_select_cloud',
  SIGNUP_SELECT_REGION = 'signup_select_region',
  SIGNUP_FILTER_REGION_SWITCH = 'signup_filter_region_switch',
  SIGNUP_COMPUTE_DEFAULT_REGION = 'signup_compute_default_region',
  SIGNUP_SALES_ASSISTED_ENG_ERROR = 'signup_sales_assisted_eng_error',
  SIGNUP_SALES_ASSISTED_OWNER_ERROR = 'signup_sales_assisted_owner_error',
  SIGNUP_ACCOUNT_CREATION_ERROR = 'signup_account_creation_error',
  SIGNUP_SALES_ASSISTED_SUCCESS = 'signup_sales_assisted_success',
  SIGNUP_SUCCESS = 'signup_success',
  SIGNUP_SUPPRESSED = 'signup_suppressed',
  EXIT_SIGNUP_INITIAL_LOAD = 'exit_signup_initial_load',
  EXIT_SIGNUP_FIRST_FORM = 'exit_signup_first_form',
  EXIT_SIGNUP_SECOND_FORM = 'exit_signup_second_form',
  EXIT_SIGNUP_SURVEY_PAGE = 'exit_signup_survey_page',
  EXIT_SIGNUP_THANK_YOU_PAGE = 'exit_signup_thank_you_page',
  SIGNUP_ERROR_BOUNDARY_EXCEPTION = 'signup_error_boundary_exception',
  SIGNUP_ROOT_ERROR = 'signup_root_error',
  GOTO_LOGIN_PAGE_LINK_CLICKED = 'goto_login_page_link_clicked',
  FETCH_VISIBLE_REGIONS_FAILS = 'fetch_visible_regions_fails',
  GET_LISTING_INFO_ERROR = 'signup_get_listing_info_error',
  GET_LISTING_INFO_START = 'signup_get_listing_info_start',
  GET_LISTING_INFO_SUCCESS = 'signup_get_listing_info_success',
  POST_SIGNUP_ACQUISITION_SOURCE_SURVEY = 'post_signup_acquisition_source_survey',
}

export enum AzureEventName {
  AZURE_FORM_VALIDATION_ERROR_ON_SUBMIT = 'azure_form_validation_error_on_submit',
  AZURE_FORM_COMPLETE = 'azure_form_complete',
  AZURE_VALIDATE_EMAIL = 'azure_validate_email',
  AZURE_ACCOUNT_CREATION_ERROR = 'azure_account_creation_error',
  AZURE_SUCCESS = 'azure_success',
  EXIT_AZURE_INITIAL_LOAD = 'exit_azure_initial_load',
  EXIT_AZURE_FORM = 'exit_azure_form',
  EXIT_AZURE_THANK_YOU_PAGE = 'exit_azure_thank_you_page',
  AZURE_ROOT_ERROR = 'azure_root_error',
  AZURE_TOKEN_ERROR = 'azure_token_error',
}

export enum GcpEventName {
  GCP_ROOT_ERROR = 'gcp_root_error',
}

export enum AwsEventName {
  AWS_FORM_VALIDATION_ERROR_ON_SUBMIT = 'aws_form_validation_error_on_submit',
  AWS_FORM_COMPLETE = 'aws_form_complete',
  AWS_SUCCESS = 'aws_success',
  AWS_VALIDATE_EMAIL = 'aws_validate_email',
  EXIT_AWS_INITIAL_LOAD = 'exit_aws_initial_load',
  EXIT_AWS_FORM = 'exit_aws_form',
  EXIT_AWS_THANK_YOU_PAGE = 'exit_aws_thank_you_page',
  AWS_ACCOUNT_ADD_SUBSCRIPTION_ERROR = 'aws_account_add_error',
}

//list of events that should be flushed immediately
//TODO: use the logger's immediate flag to signal when to flush the queue right away once core-ui supports this.
const immediatelyFlushEvents: string[] = [
  SignupEventName.EXIT_SIGNUP_INITIAL_LOAD,
  SignupEventName.EXIT_SIGNUP_FIRST_FORM,
  SignupEventName.EXIT_SIGNUP_SECOND_FORM,
  SignupEventName.EXIT_SIGNUP_SURVEY_PAGE,
  SignupEventName.EXIT_SIGNUP_THANK_YOU_PAGE,
  AzureEventName.AZURE_TOKEN_ERROR,
  AzureEventName.EXIT_AZURE_INITIAL_LOAD,
  AzureEventName.EXIT_AZURE_FORM,
  AzureEventName.EXIT_AZURE_THANK_YOU_PAGE,
  AwsEventName.EXIT_AWS_INITIAL_LOAD,
  AwsEventName.EXIT_AWS_FORM,
  AwsEventName.EXIT_AWS_THANK_YOU_PAGE,
];

export class SignupLogger {
  queue: FullEvent[];
  timer: number | null; //reference to the timer returned by setTimeout

  constructor(
    private formId: string,
    private interval: number = 30000, //milliseconds to wait before executing sendTelemetry()
  ) {
    this.queue = [];
    this.timer = null;
  }

  processEvent(log: FullEvent): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [signupParams, marketingParams] = parseQueryParams((window as any).location.search);
    const logWithFormId: FullEvent = {
      ...log,
      data: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(log.data as { [key: string]: any }),
        formId: this.formId,
        timestamp: new Date().toISOString(),
        parsedParams: { ...signupParams, ...marketingParams },
        fullUrl: window.location.href,
      },
    };
    this.queue.push(logWithFormId);

    if (immediatelyFlushEvents.includes(logWithFormId['event'])) {
      if (typeof this.timer === 'number') {
        window.clearTimeout(this.timer);
      }
      this.sendTelemetry();
    } else if (this.queue.length === 1) {
      //added the first event to the queue, start the timer
      this.timer = window.setTimeout(this.sendTelemetry.bind(this), this.interval);
    }
  }

  sendTelemetry(): void {
    if (!this.queue.length) {
      return;
    }
    const localQueue = this.queue;
    this.queue = [];
    this.timer = null;
    handleTelemetryRequest(localQueue);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logError(error: { component: string; event: string; data: { [key: string]: any } }): void {
    //Build the error event
    const event: FullEvent = {
      ...error,
      type: SignupEventType.UI_ERROR,
      location: [error.component],
      data: {
        ...error.data,
        formId: this.formId,
        timestamp: new Date().toISOString(),
        fullUrl: window.location.href,
      },
      interaction: false,
      flags: {},
    };
    handleTelemetryRequest([event]);
  }
}

function handleTelemetryRequest(events: FullEvent[]) {
  if (!navigator.sendBeacon) {
    // non-blocking.
    void SignupApi.sendTelemetry(events);
  } else {
    void SignupApi.sendTelemetryWithBeacon(events);
  }
}
