import { AwsMarketplaceType } from '@signup/shared';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { SignupPageProvider } from './SignupContext';
import { SignupPage } from './SignupPage';

export interface SignupPageProps extends RouteComponentProps {
  formId: string;
  pageNotFound?: boolean;
  isAzure?: boolean;
  isGcp?: boolean;
  awsType?: AwsMarketplaceType;
}

export const SignupPageRoot = (props: SignupPageProps) => {
  return (
    <SignupPageProvider>
      <SignupPage {...props} />
    </SignupPageProvider>
  );
};
