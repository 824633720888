// copied and modified from core-ui until we bump to the later core-ui version.
import React from 'react';
import type { SpacingSizes } from '@snowflake/core-ui';
import { Block, Color, Row, Text } from '@snowflake/core-ui';
import { FormattedMessage } from 'react-intl';

interface OrDividerProps {
  marginTop?: SpacingSizes;
  marginBottom?: SpacingSizes;
}

export const OrDivider = ({ marginTop = 24, marginBottom = 24 }: OrDividerProps) => (
  <Row
    align="center"
    verticalAlign="middle"
    height="0.1em"
    marginTop={marginTop}
    marginBottom={marginBottom}
  >
    <Block
      backgroundColor={Color.Gray30}
      lineHeight="0.1em"
      textAlign="center"
      width="100%"
      height="100%"
    />
    <Text
      display="block"
      height="100%"
      lineHeight="0.1em"
      marginLeft="20px"
      marginRight="20px"
      whiteSpace="nowrap"
      color={Color.Gray70}
    >
      <FormattedMessage id="OR" />
    </Text>
    <Block
      backgroundColor={Color.Gray30}
      lineHeight="0.1em"
      textAlign="center"
      width="100%"
      height="100%"
    />
  </Row>
);
OrDivider.displayName = 'OrDivider';
