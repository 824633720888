import { default as React, useCallback, useState } from 'react';
import { SignupReason } from './SignupCardV2';
import { Checkbox, Color, Column, Row, Text, TextLink, useTelemetry } from '@snowflake/core-ui';
import { SignupFormFields, useSignupFormData } from './SignupFormDataContext';
import { FormattedMessage } from 'react-intl';
import StepProgressBar from './StepProgressBar';
import { CardTemplate } from '../../shared/CardTemplate';
import { FormInputsConfig, SignupFormInputs } from './SignupFormInputs';
import { SignupCardButton } from './SignupCardButton';
import { useSignupFormController } from './SignupFormControllerContext';
import {
  useEditionAndCloudInputConfigs,
  useNonStudentInputConfigs,
  useStudentInputConfigs,
} from './inputConfigs';
import { SignupEventName } from '../../../../utils/SignupLogger';

interface Props {
  continueNext: () => void;
  goBack: () => void;
}

const FormTwoFieldNames: SignupFormFields[] = [
  'schoolName',
  'companyName',
  'jobTitle',
  'edition',
  'cloud',
  'region',
  'termsAgreement',
];

export const SignupFormStepTwo = (props: Props) => {
  const formData = useSignupFormData();
  const { setField, signupReason, termsAgreement } = formData;
  const { submitSignupForm } = useSignupFormController();
  const { continueNext, goBack } = props;
  const [stepStartTime, _] = useState(Date.now());
  const [forceRequired, setForceRequired] = useState(false);
  const studentInputConfigs = useStudentInputConfigs();
  const nonStudentInputConfigs = useNonStudentInputConfigs();
  const editionAndCloudInputConfigs = useEditionAndCloudInputConfigs();

  const inputConfigs: FormInputsConfig = [
    ...(signupReason === SignupReason.Student ? studentInputConfigs : nonStudentInputConfigs),
    ...editionAndCloudInputConfigs,
  ];

  const { validateField } = useSignupFormController();
  const { logAction } = useTelemetry();

  const onSubmit = useCallback(async () => {
    const invalidFieldNames = FormTwoFieldNames.filter(
      fieldName => validateField(fieldName, formData[fieldName]) !== true,
    );

    if (invalidFieldNames.length > 0) {
      setForceRequired(true);
      logAction(
        SignupEventName.SIGNUP_FORM_TWO_VALIDATION_ERROR_ON_SUBMIT,
        'ui_click',
        'SignupCard',
        {
          secondFormValidationErrors: invalidFieldNames,
        },
      );
      return;
    }

    void submitSignupForm({ formTwoCompletionTime: (Date.now() - stepStartTime) / 1000 });
    continueNext();
  }, [submitSignupForm, stepStartTime, continueNext, validateField, formData, logAction]);

  return (
    <CardTemplate>
      <Column width="100%" rowGap="24px">
        <StepProgressBar step={2} totalSteps={2}>
          <Text fontSize="16px">
            <FormattedMessage id="Now, let’s set up your account" />
          </Text>
        </StepProgressBar>
        <SignupFormInputs configs={inputConfigs} forceRequired={forceRequired} />
        <Row>
          <Column align="left" verticalAlign="top" marginRight={8}>
            <Checkbox
              name="terms-agreement"
              aria-label="terms-agreement"
              checked={termsAgreement}
              value="terms-agreement"
              onChange={() => setField('termsAgreement', !termsAgreement)}
              required={true}
            />
          </Column>
          <Column align="left" verticalAlign="top">
            <Text size="small" color={Color.Gray70} marginBottom={8}>
              <FormattedMessage
                id="I have read and agree to the <tos>Snowflake Self Service On Demand Terms</tos>."
                values={{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  tos: function TOS(...chunks: any[]) {
                    return (
                      <TextLink
                        href="https://www.snowflake.com/wp-content/uploads/2017/11/Self-Serve-On-Demand-TOS.pdf"
                        target="_blank"
                      >
                        {chunks}
                      </TextLink>
                    );
                  },
                }}
              />
            </Text>
            {forceRequired && !termsAgreement ? (
              <Text color={Color.Red50} size="small">
                <FormattedMessage id="Check the box to continue" />
              </Text>
            ) : null}
          </Column>
        </Row>
        <Row columnGap="16px">
          <Column width="100%">
            <SignupCardButton onClick={goBack} buttonType="secondary">
              <FormattedMessage id="Back" />
            </SignupCardButton>
          </Column>
          <Column width="100%">
            <SignupCardButton onClick={onSubmit}>
              <FormattedMessage id="Get started" />
            </SignupCardButton>
          </Column>
        </Row>
      </Column>
    </CardTemplate>
  );
};
