// from webpack.DefinePlugin
import { SignupLogger } from './SignupLogger';

declare const ASSET_PATH: string;
declare const BUILD_ID: string;

import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { SignupErrorBoundary } from './ErrorBoundary';

// list of available locales, i.e. language/region pairs
// the locale listed last for a given language "[xx]-YY"
// will be the fallback locale used for that language in unsupported regions
// tl;dr ARRAY ORDER MATTERS!!!
let appAllowList: string[] = [];
// This line should not depend on process.env.NODE_ENV. It probably only works because it's always server rendered.
if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
  appAllowList = ['en-US', 'ja', 'fr-FR', 'ko'];
} else {
  // non-production environment for testing (show all available languages including zalgo)
  appAllowList = ['en-US', 'ja', 'fr-FR', 'ko', 'zalgo']; // full list
}

const localeAllowList: { [key: string]: string } = appAllowList.reduce((acc, locale) => {
  const localeList: { [key: string]: string } = {
    ...acc,
    [locale]: locale,
  };
  // fallback on the same language in the region listed last in the allow list if region exists
  if (locale.indexOf('-') !== -1) {
    localeList[languageFromLocale(locale)] = locale;
  }
  return localeList;
}, {});

export function getLocale(): string {
  // TODO: (JIRA I18NUI-8) replace locale selection with robust implementation
  const searchParams = new URLSearchParams(window.location.search);
  const locale: string =
    searchParams.get('_l') ||
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (navigator as { [key: string]: any }).userLanguage ||
    navigator.language;
  return localeAllowList[locale] || localeAllowList[languageFromLocale(locale)] || 'en-US';
}

export function languageFromLocale(locale: string): string {
  let separator = locale.indexOf('-');
  if (separator < 0) {
    separator = locale.length;
  }
  return locale.substring(0, separator);
}

// TODO: how many retries makes sense?
const MAX_RETRIES = 20;

const messageCache: {
  [locale: string]:
    | Error
    | {
        [id: string]: string;
      };
} = {};

// loadMessages must produce a blocking request as the entire app depends on the messages it loads.
// (or at least it will once all user-facing strings in the app have been internationalized)
// A failure to load a message bundle is generally not indicative of a problem here. More likely it
// indicates there is a problem somewhere else in the application or in the build.
export async function loadMessages(
  locale: string,
  { isRoot = false, retries = 0 } = {},
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  try {
    const response = await fetch(`${ASSET_PATH}localization/${locale}.${BUILD_ID}.json`);
    const messages = await response.json();
    messageCache[locale] = messages;
  } catch (error) {
    // swallowing errors for root lets us distinguish between language loading errors and other errors
    if (isRoot) return true;

    // retry in case of temporary network failure
    // TODO: should there be a delay between retries? perhaps with a backoff?
    if (retries < MAX_RETRIES) return loadMessages(locale, { isRoot, retries: retries + 1 });

    console.error(
      `Could not fetch language bundle "${locale}". Exceeded retry limit ${MAX_RETRIES}.`,
    );

    // no way to cache bust this except to reload the page 😢
    messageCache[locale] = error;
  }
}

function getMessages(locale: string) {
  if (messageCache[locale]) {
    return messageCache[locale];
  }
  return loadMessages(locale);
}

const InnerIntlProvider: React.FC<{ signupLogger: SignupLogger }> = props => {
  const { children } = props;
  const locale = getLocale();
  const messagesOrError = getMessages(locale);
  if (messagesOrError instanceof Error) {
    const error = messagesOrError;
    throw error;
  }
  const messages = messagesOrError;
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <IntlProvider locale={locale === 'zalgo' ? 'en-US' : locale} messages={messages as any}>
      <SignupErrorBoundary signupLogger={props.signupLogger}>{children}</SignupErrorBoundary>
    </IntlProvider>
  );
};

export const AsyncIntlProvider: React.FC<{ signupLogger: SignupLogger }> = props => (
  // TODO: render a loading spinner while waiting?
  <SignupErrorBoundary signupLogger={props.signupLogger}>
    <React.Suspense fallback={<div />}>
      <InnerIntlProvider {...props} />
    </React.Suspense>
  </SignupErrorBoundary>
);
