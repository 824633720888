import { useEffect, useState } from 'react';
import { Coordinates } from '../../../../utils/SortAvailableRegionsByDistance';
import * as configs from '../../../../utils/configurations';

export function useCoordinates() {
  const [coordinates, setCoordinates] = useState<Coordinates | undefined>(undefined);

  useEffect(() => {
    // Set coordinates based on IP lookup
    const ipCoordinates = configs.getCoordinates();
    const foundCoordinates: Coordinates | undefined = ipCoordinates?.length
      ? ipCoordinates
      : undefined; // happens if we fail to lookup IP
    setCoordinates(foundCoordinates);
  }, []);
  return coordinates;
}
