import { ListingInformation } from '@signup/shared';
import { Block, Color, Shimmer, Text } from '@snowflake/core-ui';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

// This component should either have isLoading as true, or have listingInformation be present.
// Any other case is an error since we have no information to show.
export const ListingTitle = (props: {
  listingInformation?: ListingInformation;
  isLoading: boolean;
}) => {
  let listingName;
  if (props.isLoading) {
    listingName = (
      <Shimmer role="progressbar" aria-busy={true} height={50} marginBottom={8} marginTop={8} />
    );
  } else if (props.listingInformation) {
    listingName = (
      <Text
        className="trial-title"
        allCaps={true}
        fontSize={50}
        lineHeight="57px"
        weight="bold"
        color={Color.White}
      >
        {props.listingInformation.title}
      </Text>
    );
  }

  const providerName = (
    <Text
      allCaps={true}
      fontSize={20}
      marginBottom={32}
      color={Color.White}
      display="flex"
      flexDirection="row"
      width="100%"
    >
      <FormattedMessage
        id="By <providerName></providerName>"
        description="Displays the author of a certain package. Contains a blank that is filled by the author name."
        values={{
          providerName: function ProviderName() {
            if (props.isLoading) {
              return (
                <Shimmer
                  role="progressbar"
                  aria-busy={true}
                  marginLeft={4}
                  height={20}
                  width="15%"
                />
              );
            } else if (props.listingInformation) {
              // Ideally we wouldn't need the marginLeft, but the flex we need for the shimmer messes with the spacing
              // between "By" and the provider name.
              return (
                <span style={{ marginLeft: 4, fontWeight: 'bold' }}>
                  {props.listingInformation?.providerName}
                </span>
              );
            }
          },
        }}
      />
    </Text>
  );

  return (
    <Block>
      <Text allCaps={true} fontSize={20} style={{ color: '#e2e2e5' }}>
        <FormattedMessage id="Snowflake Marketplace Listing" />
      </Text>
      {listingName}
      {providerName}
    </Block>
  );
};
