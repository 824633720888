import { Button, Color, Text } from '@snowflake/core-ui';
import * as React from 'react';

const orange = '#f99f36';
const darkerOrange = '#dc7907';
const darkestOrange = '#925004';

const marketingButtonStyles = {
  backgroundColor: orange,
  borderBottomColor: orange,
  borderLeftColor: orange,
  borderRightColor: orange,
  borderTopColor: orange,
  borderRadius: '30px',
  paddingRight: '30px',
  paddingLeft: '30px',
  paddingTop: '20px',
  paddingBottom: '20px',
  color: Color.Gray00,
  ':hover': {
    backgroundColor: darkerOrange, // 5% darker
    boxShadow: 'rgba(0, 0, 0, 0.3) 0 1px 2px',
  },
  ':active': {
    backgroundColor: darkestOrange, // 10% darker
    boxShadow: 'none',
    transitionTimingFunction: 'step-start',
  },
};

export const MarketingButton = (props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: React.EventHandler<any>;
  children: React.ReactNode;
}) => {
  return (
    <Button use="primary" onClick={props.onClick} {...marketingButtonStyles}>
      <Text weight="bold" allCaps={true}>
        {props.children}
      </Text>
    </Button>
  );
};
