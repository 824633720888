import { AccessibleSection, Block, InfoCard, InfoCardProps } from '@snowflake/core-ui';
import React from 'react';

export function CardTemplate(props: {
  children: React.ReactNode;
  title?: string;
  subtitle?: React.ReactNode;
  aux?: React.ReactNode;
  cardProps?: Omit<InfoCardProps, 'title' | 'aux' | 'children'>;
}) {
  return (
    <InfoCard title={props.title} aux={props.aux} {...props.cardProps}>
      {props.subtitle ? (
        <AccessibleSection>
          <Block marginBottom={24}>{props.subtitle}</Block>
        </AccessibleSection>
      ) : null}
      {props.children}
    </InfoCard>
  );
}
