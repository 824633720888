import { Button, ButtonProps, Color, Text, splitBorderRadiusProps } from '@snowflake/core-ui';
import React from 'react';

type Props = ButtonProps & {
  buttonType?: 'primary' | 'secondary';
};

/**
 * A custom button component for the signup card.
 */
export const SignupCardButton = (props: Props) => {
  // TODO(huayang): try to align the style with core-ui. Currently the style is missing correct
  // states in click, and is using different colors.
  const buttonStyle: Partial<ButtonProps> =
    props.buttonType === 'secondary'
      ? {
          backgroundColor: Color.White,
          ':hover': {
            backgroundColor: Color.Gray00,
          },
        }
      : {
          use: 'primary',
          backgroundColor: '#28557C',
          ':hover': {
            backgroundColor: Color.Blue90,
          },
        };

  const { buttonType: _, ...restProps } = props;

  return (
    <Button height="40px" {...splitBorderRadiusProps('20px')} {...buttonStyle} {...restProps}>
      <Text fontWeight="600">{props.children}</Text>
    </Button>
  );
};
