import { Column, Row, Text } from '@snowflake/core-ui';
import React from 'react';

interface StepProgressBarProps {
  step: number;
  totalSteps: number;
  children: React.ReactNode;
}

const StepProgressBar: React.FC<StepProgressBarProps> = ({ step, totalSteps, children }) => {
  return (
    <Row width="100%" lineHeight="24px">
      <Column>{children}</Column>
      <Column marginLeft="auto">
        <Text>
          {step} / {totalSteps}
        </Text>
      </Column>
    </Row>
  );
};

export default StepProgressBar;
