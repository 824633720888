import { Block } from '@snowflake/core-ui';
import {
  AwsMarketplaceType,
  Cloud,
  FeatureFlag,
  AwsMpAccount,
  ABExperimentName,
} from '@signup/shared';
import queryString from 'query-string';
import * as React from 'react';
import { AwsErrorCard } from '../components/Cards/AwsErrorCard';
import { AwsCard } from '../components/Cards/forms/AwsCard';
import { AzureCard } from '../components/Cards/forms/AzureCard';
import { GcpCard } from '../components/Cards/forms/GcpCard';
import { SignupCard } from '../components/Cards/forms/SignupCard/SignupCard';
import { PageNotFoundCard } from '../components/Cards/PageNotFoundCard';
import { CertificationsFooter } from '../components/CertificationsFooter';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { CardMarketingTitle } from '../components/MarketingText/CardMarketingTitle';
import { InfoList } from '../components/MarketingText/InfoList';
import { Title } from '../components/MarketingText/Title';
import { InternalHeaders } from '../utils/InternalHeaders';
import { useSignupPageContext } from './SignupContext';
import { SignupPageProps } from './SignupPageRoot';
import { MarketingParams, parseQueryParams, SignupParams } from '../utils/ParseQueryParams';
import { useListingInformation } from './UseListingInformation';
import { ListingTitle } from '../components/MarketingText/ListingTitle';
import * as configs from '../utils/configurations';
import { SignupCardV2 } from '../components/Cards/forms/SignupCardV2/SignupCardV2';

const recaptchaRef = React.createRef();

export const SignupPage = (props: SignupPageProps) => {
  const { videoShowing } = useSignupPageContext();

  const [signupParams, marketingParams]: [SignupParams, MarketingParams] = React.useMemo(() => {
    return parseQueryParams(props.location?.search);
  }, [props.location]);

  const { listingInformation, isListingInfoLoading } = useListingInformation(signupParams.listing);

  if (props.awsType) signupParams.cloud = Cloud.AWS;

  let card;
  if (props.pageNotFound) {
    card = <PageNotFoundCard />;
  } else if (props.awsType && !signupParams.ampt) {
    card = <AwsErrorCard />;
  } else if (
    props.awsType === AwsMarketplaceType.CAPACITY ||
    props.awsType === AwsMarketplaceType.ON_DEMAND_ADD_SUBSCRIPTION
  ) {
    card = (
      <AwsCard
        signupParams={signupParams}
        marketingParams={marketingParams}
        formId={props.formId}
        recaptchaRef={recaptchaRef}
        awsMpAccount={signupParams.awsmp || AwsMpAccount.SFC_INC_MARKETPLACE_US}
        awsType={props.awsType}
      />
    );
  } else if (props.isAzure) {
    card = (
      <AzureCard
        signupParams={signupParams}
        marketingParams={marketingParams}
        formId={props.formId}
        recaptchaRef={recaptchaRef}
        queryParams={queryString.parse(props.location?.search)}
      />
    );
  } else if (props.isGcp) {
    card = <GcpCard />;
  } else {
    if (configs.getExperimentGroup(ABExperimentName.SIGNUP_FORM_REWORK) === 'ENABLED') {
      card = (
        <SignupCardV2
          signupParams={signupParams}
          marketingParams={marketingParams}
          formId={props.formId}
          recaptchaRef={recaptchaRef}
          awsType={props.awsType}
        />
      );
    } else {
      card = (
        <SignupCard
          signupParams={signupParams}
          marketingParams={marketingParams}
          formId={props.formId}
          recaptchaRef={recaptchaRef}
          awsType={props.awsType}
        />
      );
    }
  }

  const renderTitle = () => {
    // If there's a listing param in the url, we want to show a loading state while we attempt to fetch listing information
    // ListingTitle handles showing a loading state while isListingInfoLoading is true
    // So, we show ListingTitle if we are loading or have finished loading and have succesfully gotten the listing info
    // Else, we show the default Title and not the ListingTitle (fetching info had an error or no listing param in url)
    if (
      configs.isFeatureEnabled(FeatureFlag.FF_ENABLE_COBRANDED_SIGNUP_PAGE) &&
      signupParams.listing &&
      (isListingInfoLoading || listingInformation)
    ) {
      return (
        <ListingTitle listingInformation={listingInformation} isLoading={isListingInfoLoading} />
      );
    } else {
      return (
        <Title awsType={props.awsType} trialType={signupParams.trialType} lab={signupParams.lab} />
      );
    }
  };

  const marketingTextNotRequired = (): boolean => {
    return (
      props.pageNotFound ||
      props.isAzure ||
      props.isGcp ||
      props.awsType === AwsMarketplaceType.CAPACITY ||
      (!!props.awsType && !signupParams.ampt)
    );
  };

  const renderLeftText = () => {
    return (
      <Block
        className="trial-left-container"
        display="flex"
        flexDirection="column"
        justifyContent="top"
      >
        {renderTitle()}
        <InfoList
          awsType={props.awsType}
          listingInformation={listingInformation}
          isLoading={isListingInfoLoading}
          lab={signupParams.lab}
        />
      </Block>
    );
  };

  return (
    <Block className="trial-page-container">
      <InternalHeaders />
      <Header />
      <Block className="trial-responsive-flex-container">
        {marketingTextNotRequired() ? null : renderLeftText()}
        <Block
          className="trial-right-container"
          display="flex"
          flexDirection="column"
          justifyContent="top"
          maxWidth={videoShowing.value ? '736px' : '420px'}
        >
          {marketingTextNotRequired() ? null : (
            <CardMarketingTitle awsType={props.awsType} trialType={signupParams.trialType} />
          )}
          {card}
        </Block>
      </Block>
      {marketingTextNotRequired() ? <CertificationsFooter /> : null}
      <Footer recaptchaRef={recaptchaRef} pageNotFound={props.pageNotFound} />
    </Block>
  );
};
