import * as React from 'react';
import { Block, Color, Row } from '@snowflake/core-ui';

export type ProgressBarProps = {
  readonly currentStep: number;
  readonly totalSteps: number;
};

type StepProps = {
  readonly isActive: boolean;
};

function Step(props: StepProps) {
  const color = props.isActive ? Color.Blue50 : Color.Gray30;
  return (
    <Block
      data-testid="progress-bar-step"
      backgroundColor={color}
      height={4}
      width={25}
      borderRadius={10}
      marginRight={4}
    />
  );
}

export function ProgressBar(props: ProgressBarProps) {
  return (
    <Row>
      {[...Array(props.totalSteps).keys()].map(i => {
        const stepNumber = i + 1;
        const isActive = stepNumber <= props.currentStep;
        return <Step isActive={isActive} key={i} />;
      })}
    </Row>
  );
}
