import { Color, Icon, Row } from '@snowflake/core-ui';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CardTemplate } from './shared/CardTemplate';
import { MarketingButton } from './shared/MarketingButton';
import { getCurrentRootUrl } from './shared/shared';

export const AwsErrorCard = () => {
  const { formatMessage } = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const snowflakeIcon = <Icon icon="snowflake" size={32} color={Color.SnowflakeBlue as any} />;

  // sends everyone to signup.snowflake.com
  const url = getCurrentRootUrl();

  return (
    <CardTemplate
      title={formatMessage({ id: 'Subscription Failure' }).toUpperCase()}
      subtitle={
        <FormattedMessage id="We couldn't identify this marketplace subscription with the token provided - please reopen this AWS subscription from the AWS marketplace listing." />
      }
      aux={snowflakeIcon}
    >
      <Row align="center" marginTop={24}>
        <MarketingButton onClick={() => (window.location.href = url)}>
          <FormattedMessage id="Sign up for trial" />
        </MarketingButton>
      </Row>
    </CardTemplate>
  );
};
