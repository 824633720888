import { Color, Text } from '@snowflake/core-ui';
import { AwsMarketplaceType, TrialType } from '@signup/shared';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSignupPageContext } from '../../pages/SignupContext';

export const CardMarketingTitle = (props: {
  awsType?: AwsMarketplaceType;
  trialType?: TrialType;
}) => {
  const { renderingOptions } = useSignupPageContext();

  const legacyGetCardMarketingTitle = () => {
    let title;
    if (props.awsType === AwsMarketplaceType.ON_DEMAND) {
      title = (
        <FormattedMessage id="Please fill out the following information to complete your Snowflake subscription!" />
      );
    } else if (props.trialType == TrialType.TRIAL_LEARN) {
      title = (
        <FormattedMessage id="Start your 120-day free Snowflake trial which includes $400 worth of free usage" />
      );
    } else {
      title = (
        <FormattedMessage id="Start your 30-day free Snowflake trial which includes $400 worth of free usage" />
      );
    }
    return title;
  };

  const title = renderingOptions.cardMarketingTitle || legacyGetCardMarketingTitle();

  return (
    <Text
      className="trial-card-marketing-text"
      fontSize={20}
      color={Color.White}
      lineHeight="30px"
      marginBottom={24}
    >
      {title}
    </Text>
  );
};
