import { useIntl } from 'react-intl';
import { LabeledValue, OptionLabeledValue, Question, useOtherOption } from './model';
import { useMemo } from 'react';
import { range, shuffle } from 'lodash';
import { getExperimentGroup } from '../../../utils/configurations';
import { ABExperimentName } from '@signup/shared';

const SURVEY_VERSION = 'v3';

export const useSnowflakeUsageQuestion = (): Question => {
  const { formatMessage } = useIntl();

  return useMemo(() => {
    const options: OptionLabeledValue[] = [
      {
        value: 'Use Snowflake as a data warehouse and data lake',
        label: formatMessage({ id: 'Use Snowflake as a data warehouse and data lake' }),
      },
      {
        value: 'Run data analysis and build visualizations',
        label: formatMessage({ id: 'Run data analysis and build visualizations' }),
      },
      {
        value: 'Load data, build a data pipeline or migrate an existing warehouse',
        label: formatMessage({
          id: 'Load data, build a data pipeline or migrate an existing warehouse',
        }),
      },
      {
        value: 'Build or train a machine learning model',
        label: formatMessage({ id: 'Build or train a machine learning model' }),
      },
      {
        value: 'Build or distribute an application with Snowflake',
        label: formatMessage({ id: 'Build or distribute an application with Snowflake' }),
      },
      {
        value: 'List or buy data from the Snowflake marketplace',
        label: formatMessage({ id: 'List or buy data from the Snowflake marketplace' }),
      },
    ];
    const order = shuffle(range(options.length));
    const shuffledOptions = order.map(index => options[index]);

    return {
      version: SURVEY_VERSION,
      questionText: {
        value: 'What will you use Snowflake for?',
        label: formatMessage({ id: 'What will you use Snowflake for?' }),
      },
      options: [shuffledOptions],
    };
  }, [formatMessage]);
};

export const useSignupReasonQuestion = (): Question => {
  const { formatMessage } = useIntl();

  const otherOption = useOtherOption(false);

  return useMemo(() => {
    const options: OptionLabeledValue[] = [
      {
        value: 'Company is considering Snowflake',
        label: formatMessage({ id: 'Company is considering Snowflake' }),
      },
      {
        value: 'Training or Certification',
        label: formatMessage({ id: 'Training or Certification' }),
      },
      {
        value: 'Personal learning and development',
        label: formatMessage({ id: 'Personal learning and development' }),
      },
    ];
    const order = shuffle(range(options.length));
    const shuffledOptions = order.map(index => options[index]);

    return {
      version: SURVEY_VERSION,
      questionText: {
        value: 'Why are you signing up for a trial?',
        label: formatMessage({ id: 'Why are you signing up for a trial?' }),
      },
      options: [[...shuffledOptions, otherOption]],
    };
  }, [formatMessage, otherOption]);
};

export const usePreferredLanguageQuestion = (): Question => {
  const { formatMessage } = useIntl();
  const otherOption = useOtherOption(true);

  return useMemo(() => {
    const noCodingExperienceValue = 'No coding language experience';
    const shouldDisableLanguageOption = (selectedOptions: { [key: string]: boolean }) => {
      return selectedOptions[noCodingExperienceValue];
    };

    const options: LabeledValue[] = [
      {
        value: 'SQL',
        label: formatMessage({ id: 'SQL' }),
        shouldDisable: shouldDisableLanguageOption,
      },
      {
        value: 'Python',
        label: formatMessage({ id: 'Python' }),
        shouldDisable: shouldDisableLanguageOption,
      },
      {
        value: 'Java',
        label: formatMessage({ id: 'Java' }),
        shouldDisable: shouldDisableLanguageOption,
      },
      {
        value: 'Scala',
        label: formatMessage({ id: 'Scala' }),
        shouldDisable: shouldDisableLanguageOption,
      },
    ];
    const order = shuffle(range(options.length));
    const shuffledOptions = order.map(index => options[index]);

    const isV2 = getExperimentGroup(ABExperimentName.SIGNUP_FORM_REWORK) === 'ENABLED';

    const v1NoExperienceOption: OptionLabeledValue[] = [
      {
        value: noCodingExperienceValue,
        label: formatMessage({ id: 'No coding language experience' }),
      },
    ];

    const v2NoExperienceOption: OptionLabeledValue[] = [
      {
        value: noCodingExperienceValue,
        label: formatMessage({ id: 'I don’t have coding experience' }),
      },
    ];

    const surveyOptions: OptionLabeledValue[][] = [
      [
        ...shuffledOptions,
        ...(!isV2 ? v1NoExperienceOption : []),
        {
          ...otherOption,
          shouldDisable: shouldDisableLanguageOption,
        },
      ],
    ];

    if (isV2) {
      surveyOptions.push(v2NoExperienceOption);
    }

    return {
      version: SURVEY_VERSION,
      questionText: {
        value: 'Select your preferred language(s) to work in',
        label: formatMessage({ id: 'Select your preferred language(s) to work in' }),
      },
      options: surveyOptions,
    };
  }, [formatMessage, otherOption]);
};
