import { useIntl } from 'react-intl';
import { Role } from '@signup/shared';

export interface Country {
  label: string;
  value: string;
}

export const useRoles = (): Country[] => {
  const { formatMessage } = useIntl();
  return [
    {
      value: Role.BI_ANALYST,
      label: formatMessage({ id: 'BI / Analyst' }),
    },
    {
      value: Role.BUSINESS_EXECUTIVE,
      label: formatMessage({ id: 'Business Executive' }),
    },
    {
      value: Role.CONSULTANT,
      label: formatMessage({ id: 'Consultant' }),
    },
    {
      value: Role.DATA_ENGINEER,
      label: formatMessage({ id: 'Data Engineer' }),
    },
    {
      value: Role.DATA_SCIENTIST,
      label: formatMessage({ id: 'Data Scientist' }),
    },
    {
      value: Role.DEVELOPER_ENGINEER,
      label: formatMessage({ id: 'Developer / Engineer' }),
    },
    {
      value: Role.IT_DBA,
      label: formatMessage({ id: 'IT / DBA' }),
    },
    {
      value: Role.PRODUCT_MANAGER,
      label: formatMessage({ id: 'Product Manager' }),
    },
    {
      value: Role.MARKETING,
      label: formatMessage({ id: 'Marketing' }),
    },
    {
      value: Role.SECURITY,
      label: formatMessage({ id: 'Security' }),
    },
    {
      value: Role.TECHNICAL_EXECUTIVE,
      label: formatMessage({ id: 'Technical Executive' }),
    },
    {
      value: Role.STARTTUP_FOUNDER,
      label: formatMessage({ id: 'Startup Founder' }),
    },
    {
      value: Role.STUDENT,
      label: formatMessage({ id: 'Student' }),
    },
    {
      value: Role.OTHER,
      label: formatMessage({ id: 'Other' }),
    },
  ];
};
