import React, { useCallback, useMemo } from 'react';
import { CardTemplate } from '../../shared/CardTemplate';
import { FormattedMessage } from 'react-intl';
import { Color, Column, Row, Text, TextLink } from '@snowflake/core-ui';
import { SignupCompleteHeader } from './SignupCompleteHeader';
import { useSignupFormController } from './SignupFormControllerContext';
import { GettingStartedVideo } from '../../ThankYouCard/ThankYouCardBody';
import { SignupCardButton } from './SignupCardButton';
import { useSignupPageContext } from '../../../../pages/SignupContext';

function useHints() {
  const { docsLocale } = useSignupPageContext();

  const renderHint = useCallback((imageUrl: string, text: JSX.Element) => {
    return (
      <Row columnGap="16px" align="center">
        <Column height="48px" width="48px" align="center" verticalAlign="middle">
          <img src={imageUrl} />
        </Column>
        <Column flexGrow="1" verticalAlign="middle">
          <Text fontWeight="600" lineHeight="150%">
            {text}
          </Text>
        </Column>
      </Row>
    );
  }, []);

  return useMemo(() => {
    return (
      <>
        {renderHint(
          '/static/images/snowflake-document.svg',
          <FormattedMessage
            id="Get started with <doc>Snowflake documentation</doc>"
            values={{
              // eslint-disable-next-line
              doc: (...chunks: any[]) => {
                return (
                  <TextLink
                    href={`https://docs.snowflake.com/${docsLocale.value}/`}
                    color={Color.Blue50}
                    target="_blank"
                    fontWeight="600"
                  >
                    {chunks}
                  </TextLink>
                );
              },
            }}
          />,
        )}
        {renderHint(
          '/static/images/virtual-onboarding.svg',
          <FormattedMessage
            id="Sign up for a free, instructor-led <lab>Virtual Hands-On Lab</lab>"
            values={{
              // eslint-disable-next-line
              lab: (...chunks: any[]) => {
                return (
                  <TextLink
                    href="https://www.snowflake.com/virtual-hands-on-lab/?utm_cta=self-service-trial-thank-you-vhol"
                    color={Color.Blue50}
                    target="_blank"
                    fontWeight="600"
                  >
                    {chunks}
                  </TextLink>
                );
              },
            }}
          />,
        )}
        {renderHint(
          '/static/images/solution-center.svg',
          <FormattedMessage
            id="Explore industry-specific user cases and walkthroughs in our <sol>Solutions Center</sol>"
            values={{
              // eslint-disable-next-line
              sol: (...chunks: any[]) => {
                return (
                  <TextLink
                    href="https://developers.snowflake.com/solutions/"
                    color={Color.Blue50}
                    target="_blank"
                    fontWeight="600"
                  >
                    {chunks}
                  </TextLink>
                );
              },
            }}
          />,
        )}
      </>
    );
  }, [renderHint, docsLocale?.value]);
}

interface SignupCompleteCardProps {
  onTryAgain: () => void;
}

export const SignupCompleteCard = ({ onTryAgain }: SignupCompleteCardProps) => {
  const hints = useHints();
  const { accountCreationFinished, creationErrorCode } = useSignupFormController();

  const renderResources = () => {
    return (
      <>
        <Row fontSize="14px" align="center">
          <FormattedMessage id="Meanwhile, here are a few resources to check out:" />
        </Row>
        {hints}
        <GettingStartedVideo />
      </>
    );
  };

  const renderTryAgainButton = () => {
    return (
      <SignupCardButton width="100%" onClick={onTryAgain}>
        <FormattedMessage id="Try again" />
      </SignupCardButton>
    );
  };

  return (
    <CardTemplate
      cardProps={{
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingBottom: '24px',
        fullbleed: true,
      }}
    >
      <Column rowGap="24px" width="100%">
        <Row align="center">
          <SignupCompleteHeader />
        </Row>
        {accountCreationFinished && !creationErrorCode && renderResources()}
        {accountCreationFinished && creationErrorCode && renderTryAgainButton()}
      </Column>
    </CardTemplate>
  );
};
