import { Color, Column, Row, Text } from '@snowflake/core-ui';
import * as React from 'react';

export const SignupErrorPage: React.FC = () => {
  return (
    <Row height="100vh" verticalAlign="middle" align="center" backgroundColor={Color.Gray10}>
      <Column>
        <Text
          size="xxlarge"
          color={Color.Gray85}
          fontWeight={700}
          textAlign="center"
          marginBottom="0.5em"
        >
          Something went wrong
        </Text>
        <Text size="large" textAlign="center" color={Color.Gray70} marginBottom="2em">
          Please refresh the page
        </Text>
      </Column>
    </Row>
  );
};
