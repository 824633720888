import { default as React } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CardTemplate } from '../shared/CardTemplate';
import { checkmarkIcon } from '../shared/shared';

export const GcpCard = () => {
  const { formatMessage } = useIntl();

  function renderThankYouCardHeader(): [string, React.ReactChild, React.ReactChild] {
    const thankYouTitle = formatMessage({
      id: 'You are now subscribed to Snowflake!',
    }).toUpperCase();
    const thankYouSubtitle = (
      <FormattedMessage id="Nothing else is needed on your end. Please continue to use your Snowflake account(s) normally!" />
    );
    const icon = checkmarkIcon;

    return [thankYouTitle, thankYouSubtitle, icon];
  }

  const renderCard = () => {
    const [thankYouTitle, thankYouSubtitle, icon] = renderThankYouCardHeader();
    return (
      <CardTemplate title={thankYouTitle} subtitle={thankYouSubtitle} aux={icon}>
        <></>
      </CardTemplate>
    );
  };

  return renderCard();
};
