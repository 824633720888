import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';
import { SignupReason } from './SignupCardV2';
import { Cloud, Edition } from '@signup/shared';

// Define the shape of the context state
export interface SignupFormDataState {
  firstName: string;
  lastName: string;
  email: string;
  countryIsoCode: string;
  signupReason: SignupReason | null;
  schoolName: string;
  companyName: string;
  jobTitle: string;
  edition: Edition;
  cloud: Cloud | null;
  region: string;
  termsAgreement: boolean;
  setField: (field: SignupFormFields, value: any) => void;
  optInEmailAgreement: boolean;
  optOutEmailAgreement: boolean;
  givenEmailOptInChoice: boolean;
}

export type SignupFormFields = Exclude<keyof SignupFormDataState, 'setField'>;

// Create the context
export const SignupFormDataContext = createContext<SignupFormDataState | undefined>(undefined);

// Create the provider component
const SignupFormDataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    countryIsoCode: '',
    signupReason: null,
    schoolName: '',
    companyName: '',
    jobTitle: '',
    edition: Edition.ENTERPRISE,
    cloud: null,
    region: '',
    termsAgreement: false,
    optInEmailAgreement: false,
    optOutEmailAgreement: false,
    givenEmailOptInChoice: false,
  });

  const setField = useCallback((field: SignupFormFields, value: any) => {
    setFormState(prevState => ({
      ...prevState,
      [field]: value,
    }));
  }, []);

  return (
    <SignupFormDataContext.Provider value={{ ...formState, setField }}>
      {children}
    </SignupFormDataContext.Provider>
  );
};

// Custom hook for using the context
const useSignupFormData = () => {
  const context = useContext(SignupFormDataContext);
  if (context === undefined) {
    throw new Error('useSignupFormData must be used within a SignupFormDataProvider');
  }
  return context;
};

export { SignupFormDataProvider, useSignupFormData };
