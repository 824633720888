import { Block, Color, Row, Text, TextLink } from '@snowflake/core-ui';
import { FormattedMessage } from 'react-intl';
import ReCAPTCHA from 'react-google-recaptcha';
import { default as React, RefObject } from 'react';
import { FooterCommPreference } from './FooterCommPreference';
import * as configs from '../utils/configurations';

export interface FooterProps {
  pageNotFound?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recaptchaRef: RefObject<any>;
}

const verticalBar = (
  <Text marginLeft="6px" marginRight="6px">
    &#x0007C;
  </Text>
);

const Link = (props: { message: React.ReactChild; href: string }) => {
  return (
    <TextLink target="_blank" href={props.href} textDecorationLine="none">
      {props.message}
    </TextLink>
  );
};

export const Footer = (props: FooterProps) => {
  return (
    <Block
      paddingTop="37px"
      paddingBottom="50px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      lineHeight="26px"
      color={Color.White}
      fontSize="small"
      backgroundColor={Color.Black}
    >
      <Row>
        <Link
          message={<FormattedMessage id="Privacy Notice" />}
          href="https://www.snowflake.com/privacy-policy/"
        />
        {verticalBar}
        <Link
          message={<FormattedMessage id="Site Terms" />}
          href="https://www.snowflake.com/wp-content/uploads/2017/11/Self-Serve-On-Demand-TOS.pdf"
        />
        {verticalBar}
        <TextLink className="optanon-toggle-display" textDecorationLine="none">
          <FormattedMessage id="Cookie Settings" />
        </TextLink>
        {verticalBar}
        <Link
          message={<FormattedMessage id="Do Not Share My Personal Information" />}
          href="https://www.snowflake.com/privacy-policy/#12"
        />
      </Row>
      <Row>&copy; {new Date().getFullYear()} Snowflake Inc. All Rights Reserved</Row>
      <Row>
        <FooterCommPreference />
      </Row>
      {props.pageNotFound ? null : (
        <ReCAPTCHA
          ref={props.recaptchaRef}
          size="invisible"
          sitekey={configs.getRecaptchaSiteKey()}
        />
      )}
    </Block>
  );
};
