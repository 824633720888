import { SNOWPARK_PYTHON_LAB } from '@signup/shared';

export const isSnowparkLabSignup = (lab?: string): boolean => {
  if (!lab) {
    return false;
  } else if (lab === SNOWPARK_PYTHON_LAB) {
    return true;
  }

  return false;
};
