import * as React from 'react';
import {
  behaviorReturnFocusToCausalElementOnHide,
  behaviorReturnFocusToContextElementOnHide,
  behaviorSetMaxHeightToAvailableHeight,
  behaviorSetMinWidthToContextWidth,
  Block,
  Color,
  Icon,
  LayerType,
  Text,
  SelectorMenu,
  SelectorProps,
  usePopoverLayer,
  ValidOptionValueTypes,
  Row,
  TextColor,
} from '@snowflake/core-ui';
import { useIntl } from 'react-intl';

interface InlinedSelectorProps<O extends ValidOptionValueTypes, V extends O | O[]>
  extends Pick<SelectorProps<O, V>, 'options' | 'value' | 'placeholder'> {
  selectValue: (newValue: O) => void;
  label?: string;
  buttonTextColor?: TextColor;
}

export const InlinedSelector = <O extends ValidOptionValueTypes, V extends O | O[]>(
  props: InlinedSelectorProps<O, V>,
) => {
  const { options, value, label, placeholder, selectValue, buttonTextColor } = props;

  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const usingMouseRef = React.useRef(false);
  const { formatMessage } = useIntl();

  const onSelect = (newValue: O) => {
    selectValue(newValue);
    layer.hideLayer();
  };

  const connectedMenu = (layer: LayerType) => (
    <SelectorMenu<O, V>
      options={options}
      selectedValue={value}
      onSelect={onSelect}
      hideLayer={layer.hideLayer}
      usingMouseRef={usingMouseRef}
      multiple={false}
    />
  );

  const [portal, layer] = usePopoverLayer(connectedMenu, buttonRef, {
    align: 'bottomcenter',
    offset: 4,
    onShow: undefined,
    onHide: undefined,
    rootProps: {
      width: '300px',
      role: undefined,
    },
    behaviors: [
      behaviorSetMinWidthToContextWidth,
      behaviorReturnFocusToContextElementOnHide,
      behaviorSetMaxHeightToAvailableHeight,
    ],
    disabledDefaultBehaviors: [behaviorReturnFocusToCausalElementOnHide],
  });

  return (
    <Block>
      {portal}
      <Block role="button" ref={buttonRef} cursor="pointer" onClick={layer.toggleLayer}>
        <Row>
          <Text color={buttonTextColor}>
            {label || placeholder || formatMessage({ id: 'Select an option' })}
          </Text>
          <Icon
            color={Color.Gray70}
            flexShrink={0}
            icon="angledown"
            marginLeft={12}
            size={14 as any}
          />
        </Row>
      </Block>
    </Block>
  );
};
