import * as React from 'react';
import { SignupFlowType } from '@signup/shared';
import { getSignupFlowType } from './configurations';
import { FormattedMessage } from 'react-intl';
import { ensureUnreachable } from '@signup/shared/src/utils/ensure';
import { RenderingOptions } from '../types';

export const useRenderingOptions = (): RenderingOptions => {
  const signupFlowType = getSignupFlowType();

  return React.useMemo(() => {
    switch (signupFlowType) {
      case SignupFlowType.Developer:
        /**
         * Used by marketing team to target developer users.
         */
        return {
          cardMarketingTitle: (
            <FormattedMessage id="Build on Snowflake with $400 of credits to develop apps" />
          ),
          marketingText: [
            <FormattedMessage key="1" id="Code in Python, SQL, Java, or Scala" />,
            <FormattedMessage
              key="2"
              id="Build with structured, semi-structured, or unstructured data"
            />,
            <FormattedMessage
              key="3"
              id="Single platform for data engineering, application development, data science, and more"
            />,
          ],
          hideCertifications: true,
        };

      case SignupFlowType.Startup:
        return {
          cardMarketingTitle: (
            <FormattedMessage id="Start your free Snowflake trial which includes $400 worth of free usage" />
          ),
          mainMarketingTitle: (
            <>
              <FormattedMessage
                id="Start your <linebreak></linebreak> free trial"
                values={{
                  linebreak: function LineBreak() {
                    return <br />;
                  },
                }}
              />
            </>
          ),
        };

      case SignupFlowType.Default:
        /**
         * Default signup flow.
         */
        return {
          cardMarketingTitle: (
            <FormattedMessage id="Start your 30-day free Snowflake trial which includes $400 worth of free usage" />
          ),
          marketingText: [
            <FormattedMessage key="1" id="Gain immediate access to the Data Cloud" />,
            <FormattedMessage key="2" id="Enable your most critical data workloads" />,
            <FormattedMessage
              key="3"
              id="Scale instantly, elastically, and near-infinitely across public clouds"
            />,
          ],
        };

      case SignupFlowType.Student:
        return {
          cardMarketingTitle: (
            <FormattedMessage id="Start your 120-day free Snowflake trial which includes $400 worth of free usage" />
          ),
          mainMarketingTitle: (
            <>
              <FormattedMessage
                id="Start your<linebreak></linebreak>120-day free trial"
                values={{
                  linebreak: function LineBreak() {
                    return <br />;
                  },
                }}
              />
            </>
          ),
        };
      case SignupFlowType.Azure:
      case SignupFlowType.Gcp:
      case SignupFlowType.Unknown:
        // To be removed after all signup flows are migrated.
        return {};
      default:
        ensureUnreachable(signupFlowType);
    }
  }, [signupFlowType]);
};
