import { default as React, useState } from 'react';
import { DeploymentOption, RegionSelect } from '../../../models';
import {
  generateRegionSelectData,
  loadingRegionSelect,
  useGetVisibleRegions,
} from '../../../utils/RegionSelect';
import { SignupCardProps } from '../forms/SignupCard/SignupCard';
import { isEqual, mapValues, sortBy } from 'lodash';
import { DeploymentMetadata, SignupEventType } from '@signup/shared';
import { getDeploymentsList } from '../../../utils/configurations';
import { useCoordinates } from '../forms/SignupCard/UseCoordinates';
import { sortAvailableRegionsByDistance } from '../../../utils/SortAvailableRegionsByDistance';
import { useTelemetry } from '@snowflake/core-ui';
import { SignupEventName } from '../../../utils/SignupLogger';

export function useDeploymentMetadata({
  props,
  email,
  filterVisibleRegions,
}: {
  props: SignupCardProps;
  filterVisibleRegions: boolean;
  email: string; // TODO(huayang): this can be removed in v2.
}): {
  showVisibleRegionsFilter: boolean;
  regionSelectList: RegionSelect;
  regionSelectListSortedByDistance: boolean;
  visibleRegions: DeploymentMetadata[] | undefined;
} {
  const [regionSelectList, setRegionSelectList] = useState<RegionSelect>(
    props.signupParams.listing
      ? loadingRegionSelect
      : generateRegionSelectData({ signupCardProps: props, email }),
  );
  const [regionSelectListSortedByDistance, setRegionSelectListSortedByDistance] = useState(false);

  const { logEvent } = useTelemetry();

  const [showVisibleRegionsFilter, setShowVisibleRegionsFilter] = useState(true);

  const coordinates = useCoordinates();

  const {
    visibleRegions,
    errorFetchingVisibleRegions,
  }: {
    visibleRegions: DeploymentMetadata[] | undefined;
    errorFetchingVisibleRegions: boolean;
  } = useGetVisibleRegions(props.signupParams.listing);

  React.useEffect(() => {
    // If the region is determined in the url, the logic in this hook does not need to run
    if (props.signupParams.region || props.signupParams.regionp) {
      return;
    }

    // If we just have the default regions, this switch effectively does nothing so we hide it.
    // We only check the region names, since they shouldn't change between dev/prod environments whereas the deployments might.
    // We also have to compare sorted arrays, since they could have different orders and thus would fail the equality check.
    const visibleRegionNames = sortBy(visibleRegions?.map(region => region.regionName));
    const defaultRegionNames = sortBy(getDeploymentsList().map(region => region.regionName));

    if (errorFetchingVisibleRegions || isEqual(visibleRegionNames, defaultRegionNames)) {
      setShowVisibleRegionsFilter(false);
    }
  }, [
    visibleRegions,
    errorFetchingVisibleRegions,
    props.signupParams.region,
    props.signupParams.regionp,
  ]);

  React.useEffect(() => {
    // If the region is determined in the url, the logic in this hook does not need to run
    if (props.signupParams.region || props.signupParams.regionp) {
      return;
    }

    if (!email) {
      return;
    }

    // Update the list if an email has been entered, run a check for the AWS Mumbai block
    let updatedRegionSelectList = generateRegionSelectData({ signupCardProps: props, email });
    let sorted = false;

    // Update the region select list if
    // the visibleRegions state has loaded
    // or the customer toggles the filterVisibleRegions switch
    if (visibleRegions && filterVisibleRegions) {
      updatedRegionSelectList = generateRegionSelectData({
        signupCardProps: props,
        visibleRegions,
        email,
      });
    } else if (visibleRegions && !filterVisibleRegions) {
      updatedRegionSelectList = generateRegionSelectData({ signupCardProps: props, email });
    }

    // Sort the region select if
    // the coordinates of the user's location has loaded
    if (coordinates?.length) {
      // We sort the available regions if this isn't a listing bundle signup
      // Or if it is, then if the visibleRegions has loaded
      if (!props.signupParams.listing || visibleRegions) {
        updatedRegionSelectList = sortAvailableRegionsByDistance(
          updatedRegionSelectList,
          coordinates,
        );
        sorted = true;

        const defaultRegions = mapValues(
          updatedRegionSelectList,
          // first region in sorted list is closest, default to '' if missing
          (regions: DeploymentOption[]) => regions?.[0]?.value ?? '',
        );
        // Log the default we computed for each cloud provider
        logEvent({
          event: SignupEventName.SIGNUP_COMPUTE_DEFAULT_REGION,
          type: SignupEventType.UI_RESPONSE_SUCCESS,
          data: defaultRegions,
          interaction: false,
        });
      }
    }

    setRegionSelectListSortedByDistance(sorted);
    setRegionSelectList(updatedRegionSelectList);
  }, [email, visibleRegions, filterVisibleRegions, coordinates, props, logEvent]);

  return {
    showVisibleRegionsFilter,
    regionSelectList,
    visibleRegions,
    regionSelectListSortedByDistance,
  };
}
