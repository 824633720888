import { mapValues, sortBy } from 'lodash';
import { DeploymentOption, RegionSelect } from '../models';
import * as configs from './configurations';

export type Coordinates = [number, number];

export const sortAvailableRegionsByDistance = (
  availableRegions: RegionSelect,
  coordinates: Coordinates,
): RegionSelect => {
  // We use Haversine Distance to calculate the distance between any two coordinates.
  // Slightly expensive, but most reasonably good approximations fall apart at larger distances.
  // Otherwise, we run into edge cases where point really far away can appear to be really close.
  // Source: https://en.wikipedia.org/wiki/Haversine_formula
  const computeDistance = (coord1: Coordinates, coord2: Coordinates) => {
    // Mean radius of the Earth, in km.
    const EARTH_RADIUS = 6371;

    // Useful functions used in computation
    const distance = (a: number, b: number) => (Math.PI / 180) * (a - b);
    const toRad = (angle: number) => (Math.PI / 180) * angle;

    // Some aliases for readability's sake
    const lat1 = coord1[0];
    const lat2 = coord2[0];
    const lng1 = coord1[1];
    const lng2 = coord2[1];

    const dLat = distance(lat2, lat1);
    const dLon = distance(lng2, lng1);
    const radLat1 = toRad(lat1);
    const radLat2 = toRad(lat2);

    const a =
      Math.pow(Math.sin(dLat / 2), 2) +
      Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(dLon / 2), 2);
    const c = 2 * Math.asin(Math.sqrt(a));
    return EARTH_RADIUS * c;
  };

  // Sort regions based on distance to user for each cloud provider
  return mapValues(availableRegions, deployments => {
    const deployment: DeploymentOption = deployments && deployments[0];
    const isDisabled = deployment?.disabled;
    // In the listing bundle signup flow (i.e. when a listing global id is passed in as a parameter in the url)
    // we filter the region list down by the availability of the listing in each region.
    // It's possible that a cloud has no available regions, in which case there is a single item in the clouds deployments list
    // with an empty value and disabled flag set to true. We must check this case before we sort
    const allDeployments = configs.getDeployments();
    if (!isDisabled) {
      return sortBy(deployments, (deployment: DeploymentOption) =>
        computeDistance(coordinates, allDeployments[deployment.value].coordinates),
      );
    }
    return deployments;
  });
};
